import React from 'react'

import Layout from '../../components/layout'
import FeatureLeft from '../../components/featureLeft'
import { Container, Row, Col, Image } from 'react-bootstrap'

import { Section, SectionGray, SingleSection } from '../../components/sections'

import { faGlobe, faServer, faChartLine, faCogs, faScrewdriver } from '@fortawesome/free-solid-svg-icons'

// Images
import gerarchia from '../../images/architettura/gerarchia.jpg'
import ordine from '../../images/architettura/ordine.jpg'
import sistema from '../../images/architettura/Config_sistema.jpg'

const Architettura = () => (
<Layout frame='appNav'>
    <SingleSection id='impl'>
        <Col md={12} className='text-center heading-section'>
            <h3>Architettura del sistema</h3>
            <p>La classificazione dei processi permette di definire una metodologia per creare un codice di numerazione preciso e compatibile con
                una futura integrazione all'interno del sistema informatico.
            </p>
            <p> Questa numerazione è in grado di spiegare le gerarchie fra i vari processi considerando le aree di appartenenza degli stessi.
            </p>
            <p>
                Per classificare i processi e integrarli nel sistema informatico si è quindi fatto uso di due concetti, la gerarchia e l'ordine di
                appartenenza.
            </p>
        </Col>
    </SingleSection>

    <SectionGray id='gerarchia'>
        <Container>
            <Row>
                <Col md={12} className='text-center heading-section'>
                    <h3>Gerarchia dei processi</h3>

                    <p>Si ispira alla tassonomia, concetto derivante dalle scienze naturali che è utlizzato per
                        dare un ordine secondo cui vengono classificati gli esseri viventi, strutturati in una gerarchia di
                        inclusione, nella quale un certo gruppo abbraccia altri minori ed è, a sua volta, subordinato a uno
                        maggiore.
                    </p>
                    <p>
                        I processi si sviluppano su tre livelli gerarchici.
                    </p>
                </Col>
            </Row>
        </Container>

        <Container>
            <Row>
                <FeatureLeft icon={faGlobe} title='Area' desc="Definische l'area di processo coinvolta" />
                <FeatureLeft icon={faServer} title='Servizio' desc="Definische il servizio coinvolto all'interno dell'area di processo" />
                <FeatureLeft icon={faChartLine} title='Attività' desc="Definische l'attività coinvolta all'interno del servizio" />
            </Row>
        </Container>

        <Container>
            <Row className='row-bottom-padded-md'>
                <Col md={12} className='text-center'>
                    <Image src={gerarchia} fluid />
                </Col>
            </Row>
        </Container>

    </SectionGray>

    <Section id='ordine'>
        <Container>
            <Row>
                <Col md={12} className='text-center heading-section'>
                    <h3>Ordine di appartenenza</h3>
                    <p>Ispirato all'ontologia, una delle branche fondamentali della filosofia che studia l'essere
                        in quanto tale, nonché le sue categorie fondamentali.
                    </p>
                    <p>In termini informatici l’ontologia è una rappresentazione formale e schematizzata di un campo d’interesse.
                        Essa contiene l’insieme dei concetti (entità, attributi, ecc.) e delle possibili relazioni tra gli stessi.
                    </p>
                    <p> Nel caso specifico, i processi sono legati a tre aree di riferimento.
                    </p>
                </Col>
            </Row>
        </Container>

        <Container>
            <Row>
                <FeatureLeft
                  icon={faGlobe} title='Management process' desc="Area processi gestionali. Sono svolti dai top manager e coinvolgono l'intera organizzazione.
                            Questa categoria di processi ha un impatto su tutte le attività svolte all'interno dell'istituto."
                />

                <FeatureLeft
                  icon={faCogs} title='Core process' desc="Sono i processi principali all'interno dell'organizzazione e cioè quelli che creano valore.
                            Questi processi hanno un impatto diretto sui clienti e quindi verso l'esterno."
                />

                <FeatureLeft
                  icon={faChartLine} title='Resourcess process' desc='Processi di supporto che per un corretto svolgimento dei pocessi chiave.
                            Tali porcessi sono spesso simili in tutte le aziende.'
                />
            </Row>
        </Container>

        <Container>
            <Row className='row-bottom-padded-md'>
                <Col md={12} className='text-center'>
                    <Image src={ordine} fluid />
                </Col>
            </Row>
        </Container>
    </Section>

    <SectionGray id='codifica'>
        <Container>
            <Row>
                <Col md={12} className='text-center heading-section'>
                    <h3>Codifica</h3>
                    <p>Creando un ordinamento facendo uso di questi due concetti permette di capire esattamente “dove ci si trova” quando si va a trattare
                        un singolo processo con i relativi rischi e controlli che lo concernono.
                    </p>
                    <p>Processi, rischi e controllo sono legati dallo stesso metodo di codifica, in questo modo per ogni processo si possono facilmente
                        identificare
                        i rischi ed i controlli associati
                    </p>
                </Col>
            </Row>
        </Container>

        <Container>
            <Row>
                <FeatureLeft md={3} icon={faScrewdriver} title='Livello' desc='Definische a che livello ci si riferische (processi, rischi o controlli)' />
                <FeatureLeft md={3} icon={faGlobe} title='Area' desc="Definische l'area di processo coinvolta" />
                <FeatureLeft md={3} icon={faServer} title='Servizio' desc="Definische il servizio, o ufficio, coinvolto all'interno dell'area di processo" />
                <FeatureLeft md={3} icon={faChartLine} title='Attività' desc="Definische l'attività coinvolta all'interno del servizio" />
            </Row>
        </Container>

    </SectionGray>

    <Section id='configurazione'>

        <Container>
            <Row>
                <Col md={12} className='text-center heading-section'>
                    <h3>Configurazione del sistema</h3>
                    <p>
                        La configurazione del sistema prevede tre tipi di analisi: dinamica, longitudinale e puntuale.
                    </p>
                    <p>
                        L'analisi dinamica permette di documentare processi, rischi e controlli dell'impianto SCI-RM.
                    </p>
                    <p>
                        l'Analisi longitudinale permette di analizzare il bilancio, il conto economico e la situazione finanziaria.
                    </p>
                    <p>
                        L'analisi puntuale permette di analizzare i rischi esterni.
                    </p>
                </Col>
            </Row>
        </Container>

        <Container>
            <Row className='row-bottom-padded-md'>
                <Col md={12} className='text-center'>
                    <Image src={sistema} fluid />
                </Col>
            </Row>
        </Container>
    </Section>

</Layout>

)

export default Architettura
